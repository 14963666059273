import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['map', 'list', 'trigger', 'location']

  initialize() {
    this.mobileWidth = 767
    this.mapText = "Voir la carte"
    this.listText = "Voir la liste"
  }

  connect() {
    this.initMap()
    this.setHiddenMap()
  }

  initMap() {
    const styledMapType = new google.maps.StyledMapType(
      [
        {
          "featureType": "administrative",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "saturation": -100
            },
            {
              "lightness": 20
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "saturation": -100
            },
            {
              "lightness": 40
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "saturation": -10
            },
            {
              "lightness": 30
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "simplified"
            },
            {
              "saturation": -60
            },
            {
              "lightness": 10
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "simplified"
            },
            {
              "saturation": -60
            },
            {
              "lightness": 60
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            },
            {
              "saturation": -100
            },
            {
              "lightness": 60
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            },
            {
              "saturation": -100
            },
            {
              "lightness": 60
            }
          ]
        }
      ],
      { name: "Cosmae" }
    );
    const firstLocation = { lat: parseFloat(this.locationTargets[0].dataset.latitude), lng: parseFloat(this.locationTargets[0].dataset.longitude) };
    const map = new google.maps.Map(this.mapTarget, {
      center: firstLocation,
      zoom: 13,
      mapTypeId: 'roadmap'
    });

    map.mapTypes.set("Cosmae", styledMapType);
    map.setMapTypeId("Cosmae");

    var prev_infowindow =false;

    const iconBase = window.location.origin

    this.locationTargets.forEach((location, index) => {
      const latitude = location.dataset.latitude
      const longitude = location.dataset.longitude
      const name = location.dataset.name
      const distance = location.dataset.distance
      const address1 = location.dataset.address1
      const address2 = location.dataset.address2
      const address3 = location.dataset.address3
      const lundi = location.dataset.lundi
      const mardi = location.dataset.mardi
      const mercredi = location.dataset.mercredi
      const jeudi = location.dataset.jeudi
      const vendredi = location.dataset.vendredi
      const samedi = location.dataset.samedi
      const dimanche = location.dataset.dimanche
      const url = location.dataset.url
      const latLng = new google.maps.LatLng(latitude, longitude)

      const contentString =
      '<div class="o-pickup-card">' +
      '<p class="o-pickup-card__margin">'+distance+'m</p>' +
      '<h1 class="o-pickup-card__name o-pickup-card__margin">'+name+'</h1>' +
      '<div class="o-pickup-card__margin"><p>'+address1+'</p>' +
      '<p>'+address2+'</p>' +
      '<p>'+address3+'</p></div>' +
      '<div class="o-pickup-card__margin"><p>Horaires d\'ouverture</p>' +
      '<p>'+lundi+'</p>' +
      '<p>'+mardi+'</p>' +
      '<p>'+mercredi+'</p>' +
      '<p>'+jeudi+'</p>' +
      '<p>'+vendredi+'</p>' +
      '<p>'+samedi+'</p>' +
      '<p>'+dimanche+'</p></div>' +
      '<a class="o-btn o-btn--full o-pickup-card__link" data-remote="true" data-controller="remote" data-action="ajax:success->remote#replaceInner" data-remote-response-target="#site-modal-wrapper" href="'+url+'">Sélectionner</a>' +
      "</div>";

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      })

      const marker = new google.maps.Marker({
        icon: iconBase + "/packs/media/images/icons/icon-cosmae-95f53cddcbcac9cb746ecf0b0241b931.png",
        position: latLng,
        map: map,
      })

      marker.addListener('click', () => {
        if( prev_infowindow ) {
          prev_infowindow.close();
        }
        prev_infowindow = infowindow;
        infowindow.open({
          anchor: marker,
          map,
          shouldFocus: false,
        })
      })
    })
  }

  setHiddenMap() {
    if (window.innerWidth <= this.mobileWidth ) {
      this.mapTarget.classList.add('is-hidden')
    }
  }

  toggleMapList(e) {
    if (this.mapTarget.classList.contains('is-hidden')) {
      this.mapTarget.classList.remove('is-hidden')
      this.listTarget.classList.add('is-hidden')
      e.currentTarget.innerHTML = this.listText
    } else {
      this.mapTarget.classList.add('is-hidden')
      this.listTarget.classList.remove('is-hidden')
      e.currentTarget.innerHTML = this.mapText
    }
  }
}

