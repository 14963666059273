import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.addFbScript()

    this.fixScrollTurboLinks()
  }

  addFbScript () {
    window.fbAsyncInit = function() {
      FB.init({
        appId            : facebookpixel_id,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v10.0'
      })
    }

    const script = document.createElement('script')
    script.setAttribute('async', '')
    script.setAttribute('defer', '')
    script.setAttribute('crossorigin', 'anonymous')
    // @todo change language if i18n is planned
    script.setAttribute('src', 'https://connect.facebook.net/fr_FR/sdk.js')
    document.querySelector('body').insertBefore(script, document.querySelector('body').firstChild)
  }

  openShareDialog (e) {
    e.preventDefault()

    const sharerHref = 'https://www.facebook.com/sharer/sharer.php?u='
    const title = e.currentTarget.dataset.title
    const href = e.currentTarget.dataset.href

    if (title) {
      window.open(encodeURI(sharerHref) + encodeURI(href) + '&p[title]=' + encodeURI(title), '_blank', 'width=400, height=300')
    } else {
      window.open(encodeURI(sharerHref) + encodeURI(href), '_blank', 'width=400, height=300')
    }
  }

  fixScrollTurboLinks () {
    if ('scrollRestoration' in history) history.scrollRestoration = 'manual'

    var SCROLL_POSITION = 'scroll-position'
    var PAGE_INVALIDATED = 'page-invalidated'

    // Patch the reload method to flag that the following page load originated from the page being invalidated
    Turbolinks.BrowserAdapter.prototype.reload = function () {
      sessionStorage.setItem(PAGE_INVALIDATED, 'true')
      location.reload()
    }

    // Persist the scroll position when leaving a page
    addEventListener('beforeunload', function () {
      sessionStorage.setItem(
        SCROLL_POSITION,
        JSON.stringify({
          scrollX: scrollX,
          scrollY: scrollY,
          location: location.href
        })
      )
    })

    // When a page is fully loaded:
    // 1. Get the persisted scroll position
    // 2. If the locations match and the load did not originate from a page invalidation, scroll to the persisted position
    // 3. Remove the persisted information
    addEventListener('DOMContentLoaded', function (event) {
      var scrollPosition = JSON.parse(sessionStorage.getItem(SCROLL_POSITION))

      if (shouldScroll(scrollPosition)) {
        scrollTo(scrollPosition.scrollX, scrollPosition.scrollY)
      } else if (location.hash) {
        const id = location.hash.substr(1)
        const anchor = document.getElementById(id)

        if (anchor) {
          const y = anchor.getBoundingClientRect().top
          window.scrollTo(0, y)
        }
      }

      sessionStorage.removeItem(SCROLL_POSITION)
      sessionStorage.removeItem(PAGE_INVALIDATED)
    })

    function shouldScroll (scrollPosition) {
      return (
        scrollPosition &&
        scrollPosition.location === location.href &&
        !JSON.parse(sessionStorage.getItem(PAGE_INVALIDATED))
      )
    }
  }
}
