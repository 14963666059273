import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { previous: Boolean }

  backToPrevious (e) {
    e.preventDefault()
    if (this.previousValue) {
      history.back()
    } else {
      window.location = e.target.href
    }
  }
}
