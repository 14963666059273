import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['pickupAddressInput', 'pickupAddressLink','countryAddressLink']

  openDeliveryPickupModalOnInputChange () {
    const modalUrl = this.element.dataset.modalUrl

    Rails.ajax({
      url: modalUrl,
      type: 'GET',
      success: function(data) {
        document.getElementById('site-modal-wrapper').innerHTML = data.documentElement.innerHTML
      }
    })
  }

  countryAddress() {
    const selectElement = document.getElementById('country_address');
    const countryAddressLink = this.countryAddressLinkTarget
    let href = new URL(countryAddressLink.href)
    var id = document.getElementById('country_address').value;
    href.searchParams.set('country_code', id)
    
    countryAddressLink.href = href
    selectElement.addEventListener('change', function(){
      id = document.getElementById('country_address').value;
        href.searchParams.set('country_code', id)
      
      countryAddressLink.href = href
    })
    
  }

  addressGeocomplete () {
    const searchInput = new google.maps.places.Autocomplete(this.pickupAddressInputTarget, {
      componentRestrictions: {
        country: 'fr'
      }
    })
    const pickupAddressLink = this.pickupAddressLinkTarget

    searchInput.addListener('place_changed', function() {
      let place = searchInput.getPlace()
      let address = false
      let zipcode = false
      let city = false
      let countryCode = false

      if (place.address_components) {
        place.address_components.forEach(address_component => {
          if (address_component.types.includes('street_number')) {
            address = address_component.long_name
          } else if (address_component.types.includes('route')) {
            address += (' ' + address_component.long_name)
          } else if (address_component.types.includes('postal_code')) {
            zipcode = address_component.long_name
          } else if (address_component.types.includes('locality')) {
            city = address_component.long_name
          } else if (address_component.types.includes('country')) {
            countryCode = address_component.short_name
          }
        })
      }

      let href = new URL(pickupAddressLink.href)
      if (address) {
        href.searchParams.set('address', encodeURIComponent(address))
      }
      if (zipcode) {
        href.searchParams.set('zipcode', zipcode)
      }
      if (city) {
        href.searchParams.set('city', encodeURIComponent(city))
      }
      if (countryCode) {
        href.searchParams.set('country_code', countryCode)
      }
      pickupAddressLink.href = href
    })
  }

  
}
