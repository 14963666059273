import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.selectors = {
      favoriteButton: '.o-btn-favorite'
    }
  }

  toggleFavorite(event) {
    event.preventDefault()

    let self = this
    const element = event.currentTarget
    const parent = element.parentElement

    Rails.ajax({
      url: event.currentTarget.dataset.url,
      type: 'POST',
      success: function(data) {
        const btn = data.querySelector(self.selectors.favoriteButton)
        if (element.classList.contains('o-btn-round--big')) {
          btn.className += ' o-btn-round--big'
        }
        parent.replaceChild(btn, element)
      }
    })
  }
}
