import { Controller } from 'stimulus'
import { gsap } from 'gsap'

const DURATION_REF = 20 // Duration reference for 1920 screens

export default class extends Controller {
  static targets = ['container', 'item', 'list']

  connect() {
    this.duration = DURATION_REF * window.innerWidth / 1920
    this.fillScreenWidth()
    // add another complete line
    this.duplicateList()
    this.initListsAnimation()
  }

  fillScreenWidth () {
    const listWidth = this.listTargets[0].offsetWidth

    // Calculate how many lists we need to add to fill the space
    const remainingSpace = window.innerWidth - listWidth
    const listsToAdd = Math.ceil(remainingSpace / listWidth)
    const nbItems = this.itemTargets.length

    for (let i = 0; i < listsToAdd; i++) {
      for (let j = 0; j < nbItems; j++) {
        const newItem = this.itemTargets[j].cloneNode(true)
        this.listTargets[0].appendChild(newItem)
      }
    }
  }

  duplicateList () {
    const firstList = this.listTargets[0]
    const newList = firstList.cloneNode(true)
    newList.style = ''
    this.containerTarget.appendChild(newList)

    return newList
  }

  initListsAnimation () {
    this.listTargets.forEach((list, index) => {
      this.animateList(list, index)
    })
  }

  animateList (list, index) {
    gsap.to(list, {
      x: '-100%',
      ease: 'none',
      duration: this.duration,
      onComplete: () => {
        if (index === 0) {
          list.parentNode.removeChild(list)
        } else if (index === 1) {
          this.resetXPosition(list)
          this.animateList(list, 0)
          const newList = this.duplicateList()
          this.animateList(newList, 1)
        }
      }
    })
  }

  resetXPosition (el) {
    gsap.set(el, {
      x: 0
    })
  }
}
