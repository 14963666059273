export default class FileValidator {
  constructor(input) {
    this.rules = []

    // Content types allowed
    if (input.getAttribute('accept')) {
      this.rules.push({ type: 'content_type', types: input.getAttribute('accept').split(',').map(type => type.trim()), message: 'Format incorrect' })
    }

    // Maximum file size
    if (input.dataset.maxSize) {
      this.rules.push({ type: 'size', max: parseInt(input.dataset.maxSize), message: 'Poids du fichier trop élevé' })
    }
  }

  checkMaxFiles(count, max) {
    return count > max ? [{ type: 'count', max: parseInt(max), message: 'le nombre total de fichiers est trop élevé' }] : []
  }

  checkedFile(file) {
    file.errors = this.rules.filter(rule => {
      switch (rule.type) {
        case 'content_type':
          return !rule.types.includes(file.type)
        case 'size':
          return file.size > rule.max
        default:
          return false
      }
    })

    return file
  }

  checkedFiles(files) {
    return files.map(file => this.checkedFile(file))
  }
}
