import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'btn', 'searchInput']

  toggleMenu (e) {
    if (!this.contentTarget.classList.contains('is-open')) {
      this.openMenu()
    } else {
      this.closeMenu()
    }
  }

  openMenu () {
    this.contentTarget.classList.add('is-open')
    this.btnTarget.classList.add('is-active')
    this.element.classList.add('has-menu-opened')
    setTimeout(() => {
      this.fixedBody()
    }, 300)
  }

  displaySubmenu () {
    this.element.classList.add('is-opaque')
  }

  hideSubmenu () {
    this.element.classList.remove('is-opaque')
  }

  closeMenu () {
    this.unfixedBody()
    this.contentTarget.classList.remove('is-open')
    this.btnTarget.classList.remove('is-active')
    this.element.classList.remove('has-menu-opened')
  }

  openSubmenu (e) {
    if (window.innerWidth < 1280) {
      e.preventDefault()

      const submenu = e.currentTarget.nextElementSibling
      submenu.classList.add('is-open')
      this.element.classList.add('has-submenu-opened')
    }
  }

  closeSubmenu (e) {
    const submenu = e.currentTarget.closest('.js-header-submenu')
    submenu.classList.remove('is-open')
    this.element.classList.remove('has-submenu-opened')
  }

  fixedBody () {
    const body = document.querySelector('body')
    this.bodyScrollY = window.scrollY

    body.style.top = scrollY * -1 + 'px'
    body.classList.add('is-fixed')
  }

  unfixedBody () {
    const body = document.querySelector('body')
    body.classList.remove('is-fixed')
    body.style = ''
    window.scrollY = this.bodyScrollY
  }

  focusSearchInput (e) {
    e.preventDefault()

    this.openMenu()
    this.searchInputTarget.focus()
  }
}
