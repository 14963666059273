import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['blockToShow', 'button', 'message']

  validateEmailFormat (e) {
    var email = e.currentTarget.value
    var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
    if (expr.test(email)) {
      this.blockToShowTarget.classList.remove('is-hidden')
    } else {
      this.blockToShowTarget.classList.add('is-hidden')
    }
  }

  handleButtonVisibility (e) {
    if (e.currentTarget.checked) {
      this.buttonTarget.removeAttribute('disabled')
    } else {
      this.buttonTarget.setAttribute('disabled', 'disabled')
    }
  }

  handleFormError (e) {
    this.messageTarget.data = ''
    this.messageTarget.classList.remove('is-hidden')

    let data, status, xhr
    [data, status, xhr] = e.detail
    this.messageTarget.textContent = data.message
    this.element.classList.add('has-error')
  }

  handleFormSuccess (e) {
    let data, status, xhr
    [data, status, xhr] = e.detail
    this.element.classList.remove('has-error')
    this.messageTarget.classList.add('is-hidden')

    const div = document.createElement('div')
    div.dataset.controller = 'flash'
    div.dataset.flashType = 'success'
    div.dataset.flashMessage = data.message
    document.querySelector('body').appendChild(div)
  }
}
