import MicroModal from 'micromodal'
import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { onclick: Boolean }

  connect() {
    MicroModal.init(this.config)

    if (!this.hasOnclickValue) {
      this.show()
    }
  }

  disconnect() {
    this.element.remove()
  }

  show() {
    document.body.classList.add('is-scroll-locked')
    MicroModal.show(this.element.id, this.config)
  }

  hide() {
    MicroModal.close(this.element.id)
  }

  get config() {
    return {
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      closeTrigger: 'data-micromodal-close',
      onClose: modal => {
        document.body.classList.remove('is-scroll-locked')
      }
    }
  }
}
