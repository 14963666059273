import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'trigger', 'group']
  static values = { ispanel: Boolean }

  initialize () {
    this.openedPanel = null
  }

  onChangeFacet (e) {
    this.handleUniqueCheckboxes(e)
    this.submitFormOnFieldChange(e)
  }

  handleUniqueCheckboxes (e) {
    const checked = e.currentTarget.checked
    const parent = e.currentTarget.closest('[data-form-landing-target="group"]')
    const checkboxes = parent.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false
    })

    if (checked) {
      e.currentTarget.checked = true
    }
  }

  submitFormOnFieldChange (e) {
    if (this.ispanelValue) {
      const inputId = e.currentTarget.getAttribute('id')
      if (inputId) {
        const input = document.getElementById(inputId)
        input.checked = e.currentTarget.checked
        const event = document.createEvent('HTMLEvents')
        event.initEvent('change', true, false)
        input.dispatchEvent(event)
      }
    } else {
      this.submitForm()
    }
  }

  resetFormOnSpanClick () {
    if (!this.hasFormTarget) {
      const form = document.getElementById('form-landing-filters')
      form.reset()
      this.submitForm(form)
    } else {
      this.formTarget.reset()
      this.submitForm()
    }
  }

  submitForm () {
    let form = document.getElementById('form-landing-filters')
    if (this.hasFormTarget) {
      form = this.formTarget
    }

    if (form.dataset.remote == 'true') {
      Rails.fire(form, 'submit')
    } else {
      form.submit()
    }

    this.hidePanel()
  }

  showPanel (e) {
    e.preventDefault()

    const targetId = e.currentTarget.dataset.targetId
    const panel = document.getElementById(targetId)
    const bodyOverlay = document.querySelector('.o-js-body-overlay')

    if (!panel.classList.contains('is-open')) {
      const blockClone = panel.cloneNode(true)
      this.openedPanel = blockClone
      blockClone.dataset.controller += ' form-landing'
      blockClone.setAttribute('data-form-landing-ispanel-value', 'true')
      document.querySelector('body').appendChild(blockClone)
      bodyOverlay.classList.add('is-visible')

      setTimeout(() => {
        blockClone.classList.add('is-open')
      }, 10)
    }
  }

  hidePanelOnClickOutside (e) {
    if (this.openedPanel && !this.openedPanel.contains(e.target) && this.openedPanel.classList.contains('is-open')) {
      this.hidePanel()
    }
  }

  hidePanel () {
    const bodyOverlay = document.querySelector('.o-js-body-overlay')

    if (this.ispanelValue && this.element.classList.contains('is-open')) {
      this.openedPanel = this.element
    }

    if (this.openedPanel !== null) {
      this.openedPanel.classList.remove('is-open')
      bodyOverlay.classList.remove('is-visible')

      this.openedPanel.addEventListener('transitionend', () => {
        this.openedPanel.parentNode.removeChild(this.openedPanel)
        this.openedPanel = null
      })
    }
  }
}
