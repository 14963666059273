import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    this.disableScrollBody()
  }

  disableScrollBody () {
    const body = document.querySelector('body')
    if ((window.getComputedStyle(this.containerTarget).display === 'none')) {
      body.style.overflow = 'initial'
    } else {
      body.style.overflow = 'hidden'
    }
  }

  hideLoginMobile () {
    const body = document.querySelector('body')
    this.containerTarget.style.display = 'none'
    body.style.overflow = 'inherit'
  }
}
