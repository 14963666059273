import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.setActiveInputs()
  }

  onInputChange(e) {
    this.setActiveInputs()
  }

  onInputMouseover(e) {
    this.setActiveInputs(parseInt(e.currentTarget.dataset.index))
  }

  onInputMouseout(e) {
    this.setActiveInputs()
  }

  setActiveInputs(index = this.currentIndex) {
    this.inputTargets.forEach(input => {
      input.classList.toggle('is-active', index >= 0 && parseInt(input.dataset.index) <= index)
    })
  }

  get currentIndex() {
    const currentInput = this.inputTargets.find(input => input.checked)
    if (currentInput) return parseInt(currentInput.dataset.index)
  }
}
