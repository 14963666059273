import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['captcha']

  connect () {
    if (global.mtcaptchaConfig) {
      if (this.captchaTarget.innerHTML === '') {
        const id = this.captchaTarget.getAttribute('id')
        mtcaptchaConfig.renderQueue.push(id)
      }
    }
  }
}
