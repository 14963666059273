import enquire from 'enquire.js'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['wrapper']
  static values = { simple: Boolean }

  connect () {
    enquire.register('screen and (max-width:767px)', {
      match : () => {
        if (!this.simpleValue) {
          this.handleSelectOnClick()
          this.createNewElementIntoFormList()
          this.createNewElementIntoFieldset()
          this.hideCustomSelectOnClickOutside()
        }
      }
    })
    this.handleCustomSelectOptions()
  }

  handleCustomSelectOptions () {
    for (const option of this.wrapperTarget.querySelectorAll('.o-form-collection-item .o-form-radio')) {
      option.addEventListener('click', function() {
        if (!this.classList.contains('selected')) {
          const customSelect = this.closest('fieldset').querySelector('.o-custom-select__trigger')
          const oldSelected = this.closest('.o-form-collection-list').querySelector('.o-form-collection-item .o-form-radio.selected')

          if (oldSelected) {
            oldSelected.classList.remove('selected')
          }

          this.classList.add('selected')

          if (customSelect) {
            customSelect.querySelector('span').textContent = this.textContent
          }
        }
      })
    }
  }

  createNewElementIntoFormList () {
    const formList = this.wrapperTarget.querySelector('.o-form-collection-list')
    const div = document.createElement('div')
    div.classList.add('o-form-collection-header')
    const span = document.createElement('span')
    span.innerHTML = 'Filtrer'
    span.classList.add('o-text-content-grey')

    div.append(span)

    formList.prepend(div)
  }

  createNewElementIntoFieldset () {
    const fieldset = this.wrapperTarget.querySelector('fieldset')
    const trigger = fieldset.querySelector('.o-custom-select__trigger')

    if (!trigger) {
      const label = fieldset.querySelector('.o-form-radio-label')
      const selectTrigger = document.createElement('div')
      const span = document.createElement('span')
      selectTrigger.classList.add('o-custom-select__trigger')
      span.innerHTML = label.innerHTML

      selectTrigger.append(span)

      fieldset.prepend(selectTrigger)
    }
  }

  handleSelectOnClick () {
    const fieldset = this.wrapperTarget.querySelector('fieldset')
    const bodyOverlay = document.querySelector('.o-js-body-overlay')
    this.wrapperTarget.addEventListener('click', function() {
      if (fieldset.classList.contains('open')) {
        bodyOverlay.classList.remove('is-visible')
        fieldset.classList.remove('open')
      } else {
        bodyOverlay.classList.add('is-visible')
        fieldset.classList.add('open')
      }
    })
  }

  hidePanel () {
    const fieldset = this.wrapperTarget.querySelector('fieldset')
    const bodyOverlay = document.querySelector('.o-js-body-overlay')
    bodyOverlay.classList.remove('is-visible')
    fieldset.classList.remove('open')
  }


  hideCustomSelectOnClickOutside () {
    const fieldset = this.wrapperTarget.querySelector('fieldset')
    const bodyOverlay = document.querySelector('.o-js-body-overlay')
    window.addEventListener('click', function(e) {
      if (!fieldset.contains(e.target) && fieldset.classList.contains('open')) {
        fieldset.classList.remove('open');
        bodyOverlay.classList.remove('is-visible')
      }
    });
  }
}
