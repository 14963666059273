import { Controller } from 'stimulus'
import { gsap } from 'gsap'

export default class extends Controller {
  static targets = ['slide', 'pagination']

  initialize () {
    this.activeIndex = 0
    this.delay = 4
    this.isAnimating = false
  }

  connect () {
    this.nbSlides = this.slideTargets.length

    this.interval = gsap.delayedCall(this.delay, () => {
      this.animateToNext()
    })
  }

  goToNext () {
    if (!this.isAnimating) {
      this.interval.kill()
      this.animateToNext()
    }
  }

  animateToNext () {
    this.isAnimating = true
    let nextIndex = this.activeIndex + 1
    if (nextIndex > this.nbSlides - 1) {
      nextIndex = 0
    }

    // slide animation
    const currentSlide = this.slideTargets[this.activeIndex]
    const nextSlide = this.slideTargets[nextIndex]
    currentSlide.homeSlide.hide()
    this.paginationTarget.homePagination.resetLoader()
    nextSlide.homeSlide.reveal().then(() => {
      this.isAnimating = false
      this.interval = gsap.delayedCall(this.delay, () => {
        this.animateToNext()
      })
    })

    // pagination animation
    let nextIndexPagination = nextIndex + 2
    if (nextIndex ===  this.nbSlides - 1) {
      nextIndexPagination = 1
    }
    this.paginationTarget.homePagination.goToIndex(nextIndexPagination, nextIndex)

    this.activeIndex = nextIndex
  }
}
