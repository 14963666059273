// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { start as ujs } from '@rails/ujs'
import { start as turbolinks } from 'turbolinks'
import { start as activestorage } from '@rails/activestorage'
import 'channels'

ujs()
turbolinks()
activestorage()

// --- Frontend styles ---
import '../utils/tarteaucitron'
import 'lightgallery.js/dist/css/lightgallery.css'
import '../stylesheets/application/styles'

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import { RemoteController } from 'stimulus-remote'

const application = Application.start()
const context = require.context('../controllers', true, /\.js$/)
application.register('remote', RemoteController)
application.load(definitionsFromContext(context))
