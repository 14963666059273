import flatpickr from 'flatpickr'
import { French } from 'flatpickr/dist/l10n/fr'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = { type: String }

  connect() {
    this.setup()
  }

  disconnect() {
    this.picker.destroy()
  }

  setup() {
    this.picker = flatpickr(this.element, this.options)
  }

  get type() {
    return this.hasTypeValue ? this.typeValue : 'datetime'
  }

  get options() {
    let options = this.defaultOptions

    if (this.type == 'date') {
      options = { ...options, ...this.dateOptions }
    } else if (this.type == 'time') {
      options = { ...options, ...this.timeOptions }
    } else {
      options = { ...options, ...this.datetimeOptions }
    }

    return options
  }

  get defaultOptions() {
    return {
      locale: French,
      wrap: true,
      altInput: true,
      disableMobile: true,
      onValueUpdate: (selectedDates, dateStr, instance) => {
        instance.altInput.classList.toggle('is-empty', !dateStr)
      }
    }
  }

  get datetimeOptions() {
    return {
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      altFormat: 'd F Y, H:i',
    }
  }

  get dateOptions() {
    return {
      dateFormat: 'Y-m-d',
      altFormat: 'd F Y',
    }
  }

  get timeOptions() {
    return {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      altFormat: 'H:i',
    }
  }
}
