import Glide from '@glidejs/glide'
import enquire from 'enquire.js'
import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { nb: Number }

  connect () {
    if (this.nbValue > 3) {
      this.initCarousel()
    } else {
      this.initSlider()
    }
  }

  initCarousel () {
    const glide = new Glide(this.element, {
      type: 'carousel',
      startAt: 0,
      perView: 4,
      gap: 16,
      peek: {
        before: 0,
        after: 152
      },
      breakpoints: {
        1919: {
          perView: 3
        },
        1279: {
          perView: 2,
          peek: {
            before: 0,
            after: 136
          },
        },
        1023: {
          perView: 1,
          peek: {
            before: 0,
            after: 102
          },
        }
      }
    })

    glide.mount()

    enquire.register('screen and (min-width:1024px)', {
      match : () => {
        glide.enable()
      }, unmatch: () => {
        glide.disable()
      }
    })
  }

  initSlider () {
    const glide = new Glide(this.element, {
      type: 'slider',
      startAt: 0,
      perView: 4,
      gap: 16,
      peek: {
        before: 0,
        after: 152
      },
      breakpoints: {
        1919: {
          perView: 3
        },
        1279: {
          perView: 2,
          peek: {
            before: 0,
            after: 136
          },
        },
        1023: {
          perView: 1,
          peek: {
            before: 0,
            after: 102
          },
        }
      }
    })

    glide.mount()

    enquire.register('screen and (min-width:1024px)', {
      match : () => {
        glide.enable()
      }, unmatch: () => {
        glide.disable()
      }
    })
  }
}
