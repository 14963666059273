import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.setEmptyStatus()
  }

  onInputChange(e) {
    this.setEmptyStatus()
  }

  setEmptyStatus() {
    this.inputTarget.classList.toggle('is-empty', !this.inputTarget.value)
  }
}
