import Choices from 'choices.js'
import { Controller } from 'stimulus'
import { formatIdentifier } from '../../utils/stimulus'

export default class extends Controller {
  static targets = ['select']

  connect() {
    this.element[formatIdentifier(this.identifier)] = this
    this.setup()
  }

  setup() {
    if (!this.selectTarget.classList.contains('choices__input')) {
      this.choices = new Choices(this.selectTarget, this.options)
      const event = new CustomEvent('choices-ready')
      this.element.dispatchEvent(event)
    }
  }

  update(value) {
    this.choices.setChoiceByValue(value)
  }

  get type() {
    if (this.selectTarget.hasAttribute('multiple')) {
      return 'multiple'
    } else {
      return 'single'
    }
  }

  get options() {
    let options = this.defaultOptions

    if (this.type === 'single') {
      options = { ...options, ...this.singleOptions }
    } else if (this.type === 'multiple') {
      options = { ...options, ...this.multipleOptions }
    }

    return options
  }

  get defaultOptions() {
    return {
      shouldSort: false,
      itemSelectText: '',
    }
  }

  get singleOptions() {
    return {
      searchEnabled: false,
    }
  }

  get multipleOptions() {
    return {}
  }
}
