/* ----- FORMAT IDENTIFIER : snake-case to camelCase ----- */

const formatIdentifier = identifier => {
  return identifier
    .split(/[-_]/)
    .map((w) => w.replace(/./, (m) => m.toUpperCase()))
    .join('')
    .replace(/^\w/, (c) => c.toLowerCase())
}

/* ----- FIND CONTROLLER FOR AN ELEMENT ----- */

const getControllerForElement = (controller, target, targetedIdentifier) => {
  return controller.application.getControllerForElementAndIdentifier(target, targetedIdentifier)
}

const hasControllerForElement = (controller, target, targetedIdentifier) => {
  return !!getControllerForElement(controller, target, targetedIdentifier)
}

/* ----- FIND PARENT CONTROLLER FROM ELEMENT ----- */

const getParentController = (controller, targetedIdentifier) => {
  const target = controller.element.parentElement.closest(`[data-controller*="${targetedIdentifier}"]`)
  return getControllerForElement(controller, target, targetedIdentifier)
}

const hasParentController = (controller, targetedIdentifier) => {
  return !!getParentController(controller, targetedIdentifier)
}

/* ----- FIND CONTROLLERS GLOBALLY IN APPLICATION ----- */

const controllersFilterScope = (targetedIdentifier, { targetedId = false, targetedClass = false } = {}) => {
  return ({
    context: {
      identifier,
      element: { id, classList },
    },
  }) => {
    let condition = identifier === targetedIdentifier

    if (targetedId) {
      condition = identifier === targetedIdentifier && id === targetedId
    } else if (targetedClass) {
      condition = identifier === targetedIdentifier && classList.contains(targetedClass)
    }

    return condition
  }
}

const getController = (controller, targetedIdentifier, { targetedId = false, targetedClass = false } = {}) => {
  return controller.application.controllers.find(
    controllersFilterScope(targetedIdentifier, {
      targetedId: targetedId,
      targetedClass: targetedClass,
    })
  )
}

const getControllers = (controller, targetedIdentifier, { targetedClass = false } = {}) => {
  return controller.application.controllers.filter(
    controllersFilterScope(targetedIdentifier, {
      targetedClass: targetedClass,
    })
  )
}

const hasController = (controller, targetedIdentifier, { targetedId = false, targetedClass = false } = {}) => {
  return !!getController(controller, targetedIdentifier, {
    targetedId: targetedId,
    targetedClass: targetedClass,
  })
}

export { formatIdentifier, hasControllerForElement, getControllerForElement, hasParentController, getParentController, hasController, getController, getControllers, controllersFilterScope }
