import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['thumbnail']
  static values = { identifier: String }

  play () {
    const width = this.thumbnailTarget.offsetWidth
    const height = this.thumbnailTarget.offsetHeight
    this.element.innerHTML = '<iframe width="' + width + '" height="' + height + '" src="https://www.youtube.com/embed/' + this.identifierValue + '?autoplay=1&rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
    this.element.classList.add('is-playing')
  }
}
