import { Controller } from 'stimulus'
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['selectTrigger', 'selectTarget']

  connect() {
    this.choices = new Choices(this.selectTargetTarget, this.options)
    this.enableselectTarget(this.selectTriggerTarget, this.choices)
  }

  appendAjaxOptions (event) {
    this.enableselectTarget(event.currentTarget, this.choices)
    const value = event.detail.value
    const xhr = new XMLHttpRequest()
    const method = 'POST'
    const url = event.currentTarget.dataset.url
    const params = event.currentTarget.dataset.params + '=' + value

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.response)
          const selectValues = []
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              const obj = {value: data[key], label: data[key]}
              selectValues.push(obj)
            }
          }
          this.choices.setChoices(
            selectValues,
            'value',
            'label',
            true,
          );
        }
      }
    }

    xhr.open(method, url + '?' + params, true)
    xhr.send()
  }

  enableselectTarget(trigger, target) {
    if (trigger.value.length == 0) {
      target.disable()
    } else {
      target.enable()
    }
  }

  get options() {
    let options = this.defaultOptions

    if (this.type === 'single') {
      options = { ...options, ...this.singleOptions }
    } else if (this.type === 'multiple') {
      options = { ...options, ...this.multipleOptions }
    }

    return options
  }

  get defaultOptions() {
    return {
      shouldSort: false,
      itemSelectText: '',
    }
  }
}
