import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'
import FileValidator from 'utils/file-validator'
import { imagePath } from 'utils/image'

export default class extends Controller {
  static targets = ['input', 'list', 'item', 'message']

  initialize() {
    this.uploadUrl = this.inputTarget.dataset.directUploadUrl
  }

  connect() {
    this.validator = new FileValidator(this.inputTarget)

    if (this.inputTarget.dataset.blobs) {
      const blobs = JSON.parse(this.inputTarget.dataset.blobs)
      blobs.forEach(blob => this.appendFileInList(blob))
    }
  }

  onInputChange(event) {
    const files = this.validator.checkedFiles(Array.from(this.inputTarget.files))

    files.forEach(file => {
      if (file.errors.length) {
        this.appendFileInList({
          filename: file.name,
          id: file.name,
          errors: file.errors,
        })
      } else {
        this.uploadFile(file)
      }
    })

    this.inputTarget.value = ''
  }

  onDeleteFileClick(event) {
    this.deleteFile(event.currentTarget.dataset.fileId)
  }

  deleteFile(id) {
    const fileItem = this.itemTargets.find(item => item.dataset.fileId === id)
    if (fileItem) fileItem.remove()
    this.countItems()
  }

  uploadFile(file) {
    if (this.uploadUrl) {
      const upload = new DirectUpload(file, this.uploadUrl)
      const template = this.getLoadingTemplate(file)

      this.listTarget.appendChild(template)

      upload.create((error, blob) => {
        if (error) {
          this.appendFileInList({
            filename: file.name,
            id: file.name,
            template: template,
            errors: [{ type: 'upload', message: 'Chargement impossible' }]
          })
        } else {
          this.readFileUrl(file, (url) => {
            blob.url = url
            blob.template = template
            this.appendFileInList(blob)
          })
        }
      })
    }
  }

  readFileUrl(file, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', event => callback(event.target.result))
    reader.readAsDataURL(file)
  }

  appendFileInList(blob) {
    let template = ''

    if (blob.errors) {
      template = this.getErrorTemplate(blob)
    } else if (blob.content_type.startsWith('image/')) {
      template = this.getImageTemplate(blob)
    } else {
      template = this.getFileTemplate(blob)
    }

    if (blob.template) {
      blob.template.replaceWith(template)
    } else {
      this.listTarget.appendChild(template)
    }

    blob.template = template
    this.countItems()
  }

  countItems() {
    if (this.inputTarget.dataset.maxFiles) {
      const items = this.itemTargets.filter(item => !isNaN(item.dataset.fileId))
      const errors = this.validator.checkMaxFiles(items.length, parseInt(this.inputTarget.dataset.maxFiles))
      this.displayGlobalErrors(errors)
    }
  }

  displayGlobalErrors(errors) {
    if (errors.length) {
      let messageBlock = this.hasMessageTarget ? this.messageTarget : undefined

      if (!messageBlock) {
        messageBlock = document.createElement('p')
        messageBlock.classList.add('o-form-message')
        messageBlock.setAttribute('data-forms--file-target', 'message')
        this.element.append(messageBlock)
      }

      this.element.parentNode.classList.add('is-invalid')
      messageBlock.textContent = errors.map(error => error.message).join(', ')
    } else if (this.hasMessageTarget) {
      this.element.parentNode.classList.remove('is-invalid')
      this.messageTarget.remove()
    }
  }

  getLoadingTemplate(file) {
    const template = document.createElement('div')

    template.classList.add('o-form-file-item', 'o-form-file-item--basic')
    template.setAttribute('data-forms--file-target', 'item')
    template.innerHTML = `<p class="o-form-file-name">Chargement : ${file.name}</p>`

    return template
  }

  getErrorTemplate(blob) {
    const template = document.createElement('div')
    let errorsTemplate = ''

    if (blob.errors) {
      blob.errors.forEach(error => {
        errorsTemplate += `<br> ${error.message}`
      })
    }

    template.classList.add('o-form-file-item', 'o-form-file-item--basic')
    template.setAttribute('data-forms--file-target', 'item')
    template.dataset.fileId = blob.id
    template.innerHTML = `
      <p class="o-form-file-name">Échec : ${blob.filename}${errorsTemplate}</p>
      ${this.getDeleteTemplate(blob)}
    `

    return template
  }

  getFileTemplate(blob) {
    const template = document.createElement('div')

    template.classList.add('o-form-file-item', 'o-form-file-item--basic')
    template.setAttribute('data-forms--file-target', 'item')
    template.dataset.fileId = blob.id
    template.innerHTML = `
      <p class="o-form-file-name">${blob.filename}</p>
      ${this.getDeleteTemplate(blob)}
      ${this.getInputTemplate(blob)}
    `

    return template
  }

  getImageTemplate(blob) {
    const template = document.createElement('div')

    template.classList.add('o-form-file-item', 'o-form-file-item--image')
    template.setAttribute('data-forms--file-target', 'item')
    template.dataset.fileId = blob.id
    template.innerHTML = `
      ${this.getDeleteTemplate(blob)}
      <img src="${blob.url}" alt="${blob.filename}" class="o-form-file-image">
      ${this.getInputTemplate(blob)}
    `

    return template
  }

  getInputTemplate(blob) {
    return `<input type="hidden" name="${this.inputTarget.name}" value="${blob.signed_id}">`
  }

  getDeleteTemplate(blob) {
    return `
      <button type="button" class="o-form-file-delete" data-file-id="${blob.id}" data-action="click->forms--file#onDeleteFileClick">
        <span>Supprimer</span>
        <img src="${imagePath('./icons/remove-circle.svg')}" alt="">
      </button>
    `
  }
}
