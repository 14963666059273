import { Controller } from 'stimulus'
import { formatIdentifier } from '../../utils/stimulus'
import { gsap } from 'gsap'
import SplitType from 'split-type'

export default class extends Controller {
  static targets = ['image', 'title', 'subtitle', 'button']

  connect () {
    this.element[formatIdentifier(this.identifier)] = this

    this.titleSplit = new SplitType(this.titleTarget)
  }

  reveal () {
    return new Promise(resolve => {
      this.element.classList.remove('is-revealed')
      this.element.classList.add('is-transitioning')
      this.element.classList.remove('is-hidden')

      const tl = gsap.timeline({
        onComplete: () => {
          this.element.classList.add('is-revealed')
          resolve()
        }
      })

      tl.fromTo(this.imageTargets, {
        x: '100%'
      }, {
        x: 0,
        duration: 1.2,
        ease: 'power1.out'
      })

      this.titleSplit.lines.reverse().forEach((line, index) => {
        tl.fromTo(line.querySelectorAll('.word'), {
          y: '100%'
        }, {
          y: 0,
          duration: 0.5,
          ease: 'power1.out',
          delay: index * 0.1
        }, '>-0.5')
      })

      tl.fromTo(this.subtitleTarget, {
        x: 100,
        opacity: 0
      }, {
        x: 0,
        opacity: 1,
        duration: 0.6,
        ease: 'power2.out'
      }, '>-0.4')

      tl.fromTo(this.buttonTarget, {
        y: -60,
        opacity: 0
      }, {
        y: 0,
        opacity: 1,
        duration: 0.6,
        ease: 'power2.out'
      }, '<0.15')
    })
  }

  hide () {
    gsap.to(this.element, {
      opacity: 0,
      duration: 1,
      delay: 0.5,
      onComplete: () => {
        this.element.classList.remove('is-transitioning')
        this.element.classList.add('is-hidden')
        this.element.style = ''
      }
    })
  }
}
