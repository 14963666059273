import { gsap } from 'gsap'
import enquire from 'enquire.js'
import { Controller } from 'stimulus'
import { formatIdentifier } from '../utils/stimulus'

export default class extends Controller {
  static targets = ['btn', 'dropdown']
  static values = { onebyone: Boolean, minheight: Number, once: Boolean }

  connect() {
    this.element[formatIdentifier(this.identifier)] = this
    this.handleResponsiveDropdown()
  }

  handleDropdown () {
    if ((this.element.className.indexOf('--mobile') !== -1 && window.innerWidth < 1024) || this.element.className.indexOf('--mobile') === -1 ) {
      if (!this.element.classList.contains('is-open')) {
        if (this.onebyoneValue) {
          // Close other dropdowns
          const dropdownControllers = this.element.parentElement.querySelectorAll('[data-controller="dropdown"]')
          dropdownControllers.forEach((dropdownController) => {
            if (dropdownController.dropdown.element !== this.element) {
              dropdownController.dropdown.closeDropdown()
            }
          })
        }

        this.openDropdown()
      } else {
        this.closeDropdown()
      }
    }
  }

  openDropdown (options = {animated: true}) {
    // Toggle class
    this.element.classList.add('is-open')

    // Toggle text
    if (this.btnTarget.dataset.show_less != undefined) {
      this.btnTarget.textContent = this.btnTarget.dataset.show_less
    }

    // Handle aria-expanded attribute for accessibility
    this.btnTarget.setAttribute('aria-expanded', 'true')

    // Open animation
    const oldHeight = this.dropdownTarget.offsetHeight

    if (this.minheightValue) {
      gsap.set(this.dropdownTarget, {
        maxHeight: 'initial'
      })
    }

    gsap.set(this.dropdownTarget, {
      height: 'auto'
    })

    if (options.animated) {
      gsap.from(this.dropdownTarget, {
        height: oldHeight,
        duration: 0.35,
        ease: 'power2.out'
      })
    }

    // Hide open btn if dropdown should only be opened once
    if (this.onceValue) {
      this.btnTarget.style.display = 'none'
    }
  }

  closeDropdown (options = {animated: true}) {
    // Toggle class
    this.element.classList.remove('is-open')

    // Toggle text
    if (this.btnTarget.dataset.show_more != undefined) {
      this.btnTarget.textContent = this.btnTarget.dataset.show_more
    }

    // Handle aria-expanded attribute for accessibility
    this.btnTarget.setAttribute('aria-expanded', 'false')

    // Close animation
    const newHeight = this.minheightValue ? this.minheightValue : 0

    if (options.animated) {
      gsap.to(this.dropdownTarget, {
        height: newHeight,
        duration: 0.35,
        ease: 'power2.out'
      })
    } else {
      gsap.set(this.dropdownTarget, {
        height: newHeight
      })
    }

  }

  handleResponsiveDropdown () {
    if (this.element.className.indexOf('--mobile') !== -1) {
      enquire.register('screen and (min-width:1024px)', {
        match: () => {
          this.openDropdown({animated: false})
        },
        unmatch: () => {
          this.closeDropdown({animated: false})
        }
      })
    }
  }
}
