import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'text', 'increaseButton', 'decreaseButton']

  connect() {
    this.minValueInput = parseInt(this.inputTarget.getAttribute('min'))
    this.maxValueInput = parseInt(this.inputTarget.getAttribute('max'))
    this.checkButtonsStatus()
  }

  increaseQuantity(event) {
    const inputValue = this.getInputValue()

    if (!this.inputTarget.disabled && !this.inputTarget.dataset.disabled && inputValue < this.maxValueInput) {
      this.inputTarget.value = inputValue + 1
      this.textTarget.textContent = inputValue + 1
      this.inputTarget.dispatchEvent(new Event('change'))
    }

    this.checkButtonsStatus()
  }

  decreaseQuantity(event) {
    const inputValue = this.getInputValue()

    if (!this.inputTarget.disabled && !this.inputTarget.dataset.disabled && inputValue > this.minValueInput) {
      this.inputTarget.value = inputValue - 1
      this.textTarget.textContent = inputValue - 1
      this.inputTarget.dispatchEvent(new Event('change'))
    }

    this.checkButtonsStatus()
  }

  checkButtonsStatus() {
    this.decreaseButtonTarget.disabled = this.getInputValue() <= this.minValueInput
    this.increaseButtonTarget.disabled = this.getInputValue() >= this.maxValueInput
  }

  getInputValue() {
    const value = this.inputTarget.value

    return parseInt(value)
  }
}
