import { Controller } from 'stimulus'
import { getControllers } from '../../utils/stimulus'

export default class extends Controller {
  static targets = ['variant', 'variantInput', 'addToCart', 'displayPrice', 'quantityInput', 'quantityText']

  connect() {
    this.priceInCents = this.hasDisplayPriceTarget ? this.displayPriceTarget.dataset.price : undefined
    if (typeof this.priceInCents === 'undefined') {
      this.setPrice()
    }
    this.updateDisplayPrice()
    this.setElementPixelData()
  }

  selectVariant(event) {
    event.preventDefault()

    this.variantTargets.forEach(variant => {
      variant.classList.remove('is-selected')
    })

    event.currentTarget.classList.add('is-selected')
    this.addToCartTarget.href = event.currentTarget.href
    this.updateElementPixelData({
      packaging: event.currentTarget.dataset.packaging
    })
  }

  onVariantInputChange(event) {
    const cartControllers = getControllers(this, this.identifier)

    cartControllers.forEach(controller => {
      const variantInput = controller.variantInputTargets.find(input => input.value == event.currentTarget.value)
      if (variantInput) variantInput.checked = true
      controller.priceInCents = event.currentTarget.dataset.price
      controller.updateDisplayPrice()
      controller.updateElementPixelData({
        packaging: event.currentTarget.dataset.packaging
      })
    })
  }

  onQuantityInputChange(event) {
    const cartControllers = getControllers(this, this.identifier)

    cartControllers.forEach(controller => {
      if (controller.hasQuantityInputTarget) {
        controller.quantityInputTarget.value = event.currentTarget.value
        controller.quantityTextTarget.textContent = event.currentTarget.value
        controller.updateElementPixelData({
          quantity: event.currentTarget.value
        })
        controller.element.dataset.quantity = event.currentTarget.value
      }
      controller.updateDisplayPrice()
    })
  }

  updateDisplayPrice() {
    if (this.hasDisplayPriceTarget) {
      this.displayPriceTarget.innerHTML = this.formattedTotalPrice
    }
  }

  setElementPixelData () {
    if (this.hasVariantInputTarget) {
      this.variantInputTargets.forEach(variantInput => {
        if (variantInput.checked) {
          this.element.dataset.packaging = variantInput.dataset.packaging
          console.log(this.element)
        }
      })
    } else if (this.hasVariantTarget) {
      this.variantTargets.forEach(variant => {
        if (variant.classList.contains('is-selected')) {
          this.element.dataset.packaging = variant.dataset.packaging
        }
      })
    }

    this.element.dataset.value = this.totalPriceInCents / 100

    if (this.hasAddToCartTarget) {
      this.copyPixelDataToCartBtn()
    }
  }

  updateElementPixelData (data) {
    if (data.quantity) {
      this.element.dataset.quantity = data.quantity
    }

    if  (data.packaging) {
      this.element.dataset.packaging = data.packaging
    }

    this.element.dataset.value = this.totalPriceInCents / 100

    if (this.hasAddToCartTarget) {
      this.copyPixelDataToCartBtn()
    }
  }

  copyPixelDataToCartBtn () {
    this.addToCartTarget.dataset.packaging = this.element.dataset.packaging
    this.addToCartTarget.dataset.value = this.element.dataset.value
    this.addToCartTarget.dataset.currency = this.element.dataset.currency
    this.addToCartTarget.dataset.quantity = this.element.dataset.quantity
  }

  setPrice () {
    if (this.hasVariantTarget) {
      this.variantTargets.forEach(variant => {
        if (variant.classList.contains('is-selected')) {
          this.priceInCents = variant.dataset.price
        }
      })
    }
  }

  get quantity() {
    return this.hasQuantityInputTarget ? parseInt(this.quantityInputTarget.value) : 1
  }

  get totalPriceInCents() {
    return this.priceInCents * this.quantity
  }

  get formattedTotalPrice() {
    return `${(this.totalPriceInCents / 100).toFixed(2)} €`.replace('.', ',')
  }
}
