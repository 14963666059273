import { Controller } from 'stimulus'
import { formatIdentifier } from '../../utils/stimulus'
import { gsap } from 'gsap'

export default class extends Controller {
  static targets = ['item', 'circle']

  initialize () {
    this.delay = 4
  }

  connect () {
    this.element[formatIdentifier(this.identifier)] = this

    this.activeItem = this.itemTargets[0]
    this.startLoader()
  }

  goToIndex (paginationIndex, slideIndex) {
    const currentImgContainer = this.activeItem.querySelector('.o-js-pagination-img')
    const currentImg = currentImgContainer.querySelector('img')
    const currentContent = this.activeItem.querySelector('.o-js-pagination-content')
    const currentText = this.activeItem.querySelector('.o-js-pagination-text')
    const currentBtn = this.activeItem.querySelector('.o-js-pagination-btn')

    const nextItem = this.itemTargets[paginationIndex]
    const nextImg = nextItem.querySelector('img').cloneNode()
    const nextText = nextItem.querySelector('.o-js-pagination-text').cloneNode(true)

    nextImg.classList.add('is-transitioning')
    nextText.classList.add('is-transitioning')

    currentImgContainer.appendChild(nextImg)
    currentContent.appendChild(nextText)

    gsap.to(currentImg, {
      x: '-100%',
      duration: 1,
      onComplete: () => {
        currentImg.parentNode.removeChild(currentImg)
        nextImg.classList.remove('is-transitioning')
      }
    })

    gsap.to(nextImg, {
      x: 0,
      duration: 1
    })

    gsap.to(currentText, {
      x: '-100%',
      duration: 1,
      onComplete: () => {
        currentText.parentNode.removeChild(currentText)
        nextText.classList.remove('is-transitioning')
      }
    })

    gsap.to(nextText, {
      x: 0,
      duration: 1,
      onComplete: () => {
        currentBtn.textContent = (slideIndex + 1) + '/' + (this.itemTargets.length - 1)
        this.setActiveItem(slideIndex + 1)
        this.startLoader()
      }
    })
  }

  startLoader () {
    gsap.fromTo(this.circleTarget, {
      strokeDashoffset: 230
    }, {
      strokeDashoffset: 0,
      duration: this.delay,
      ease: 'none'
    })
  }

  resetLoader () {
    gsap.to(this.circleTarget, {
      duration: 1,
      strokeDashoffset: -230,
      ease: 'none'
    })
  }

  setActiveItem (index) {
    this.itemTargets.forEach((item) => {
      item.classList.remove('is-active')
    })
    if (this.itemTargets.length > index) {
      this.itemTargets[index].classList.add('is-active')
    }
  }
}
