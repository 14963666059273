import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  showForm (e) {
    e.currentTarget.classList.add('is-d-none')
    this.formTarget.classList.remove('is-transparent')
  }
}
