import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['variants', 'submitBtn']
  static values = { validateText: String, addCartText: String }

  connect () {
    this.productForm = document.getElementById('new_order')
  }

  handleElVisibility () {
    if (window.scrollY > (this.productForm.getBoundingClientRect().top + this.productForm.offsetHeight + window.scrollY)) {
      this.element.classList.remove('is-hidden')
    } else if (!this.element.classList.contains('is-hidden')) {
      this.element.classList.add('is-hidden')

      if (this.hasVariantsTarget && this.variantsTarget.classList.contains('is-visible')) {
        this.variantsTarget.classList.remove('is-visible')
        this.submitBtnTarget.textContent = this.addCartTextValue
      }
    }
  }

  submitForm (e) {
    if (window.innerWidth < 1024 && this.hasVariantsTarget && !this.variantsTarget.classList.contains('is-visible')) {
      e.preventDefault()

      this.variantsTarget.classList.add('is-visible')
      this.addCartTextValue = e.currentTarget.textContent
      e.currentTarget.textContent = this.validateTextValue
    }
  }
}
