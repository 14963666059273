import { Controller } from 'stimulus'
import enquire from 'enquire.js'

export default class extends Controller {
  static targets = ['input', 'label']

  connect () {
    if (this.labelTarget && this.labelTarget.dataset.mobileLabel) {
      this.desktopLabel = this.labelTarget.textContent
      this.handleMobileLabel()
    }
  }

  showLabel () {
    this.labelTarget.classList.remove('is-transparent')
  }

  hideLabel () {
    this.labelTarget.classList.add('is-transparent')
  }

  handleBlurInput () {
    if (this.inputTarget.value === '') {
      this.showLabel()
    } else {
      this.hideLabel()
    }
  }

  handleMobileLabel () {
    enquire.register("screen and (max-width: 1023px)", {
      match: () => {
        this.labelTarget.textContent = this.labelTarget.dataset.mobileLabel
      },
      unmatch: () => {
        this.labelTarget.textContent = this.desktopLabel
      }
    })
  }
}
