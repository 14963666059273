import { Notyf } from 'notyf'
import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.notification = new Notyf(this.options)
  }

  connect() {
    this.open()
  }

  open() {
    this.notification.open({ type: this.data.get('type'), message: this.data.get('message') })
  }

  get options() {
    return {
      duration: 5000,
      ripple: false,
      position: {
        x: 'center',
        y: 'top',
      },
      types: [
        {
          type: 'alert',
          className: 'notyf__toast--error',
          icon: false,
        },
        {
          type: 'error',
          className: 'notyf__toast--error',
          icon: false,
        },
        {
          type: 'info',
          className: 'notyf__toast--success',
          icon: {
            tagName: 'span',
            test: '',
          },
        },
        {
          type: 'notice',
          className: 'notyf__toast--success',
          icon: {
            tagName: 'span',
            test: '',
          },
        },
        {
          type: 'success',
          className: 'notyf__toast--success',
          icon: {
            tagName: 'span',
            test: '',
          },
        },
        {
          type: 'warning',
          className: 'notyf__toast--success',
          icon: {
            tagName: 'span',
            test: '',
          },
        }
      ]
    }
  }
}
