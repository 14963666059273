import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container', 'input', 'image']

  connect() {
    this.setFocusStatus()
    this.removeFocusStatus()
  }

  setFocusStatus() {
    this.inputTarget.addEventListener('focus', () => {
      this.containerTarget.classList.add('is-focus')
    })
  }

  removeFocusStatus() {
    this.inputTarget.addEventListener('blur', () => {
      this.containerTarget.classList.remove('is-focus')
    })
  }
}
