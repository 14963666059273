// add/remove/change content
tarteaucitron.lang = {
  "middleBarHead": "☝ 🍪",
  "adblock": "Bonjour! Ce site joue la transparence et vous donne le choix des services tiers à activer.",
  "adblock_call": "Merci de désactiver votre adblocker pour commencer la personnalisation.",
  "reload": "Recharger la page",

  "alertBigScroll": "En continuant de défiler,",
  "alertBigClick": "En poursuivant votre navigation,",
  "alertBig": "vous acceptez l'utilisation de services tiers pouvant installer des cookies",

  "alertBigPrivacy": `
  <span class="title" style="color:#fff;">Nous utilisons des cookies</span>
  <span class="description" style="color:#fff;">
    Ce site utilise des cookies pour une meilleure navigation, souhaitez vous les activer ? En cliquant sur "Accepter et fermer", vous consentez aux finalités ci-dessus conformément à nos politiques de <a href="#" class="o-btn-link o-js-tarteaucitron-policy-link">Données personnelles</a>.
  </span>
  `,
  "alertSmall": "Gestion des services",
  "acceptAll": "Accepter et fermer",
  "personalize": "Paramétrer les cookies",
  "close": "Fermer",

  "privacyUrl": "En savoir plus",

  "all": "Préférences pour tous les services",

  "info": "Protection de votre vie privée",

  "disclaimer": "<strong>Cosmaé utilise des cookies sur ce site. Avec votre consentement, nous les utiliserons pour mesurer et analyser l'utilisation du site (cookies analytiques), pour l'adapter à vos intérêts (cookies de personnalisation) ou pour vous proposer l'usage de fonctionnalités au travers de services tiers (Réseaux sociaux, lecteur vidéo).",
  "allow": "Autoriser",
  "deny": "Interdire",
  "noCookie": "Ce service ne dépose aucun cookie.",
  "useCookie": "Ce service peut déposer",
  "useCookieCurrent": "Ce service a déposé",
  "useNoCookie": "Ce service n'a déposé aucun cookie.",
  "more": "En savoir plus",
  "source": "Voir le site officiel",
  "credit": "Gestion des cookies par tarteaucitron.js",
  "noServices": "Ce site n'utilise aucun cookie nécessitant votre consentement.",

  "toggleInfoBox": "Afficher/masquer les informations sur le stockage des cookies",
  "title": "Panneau de gestion des cookies",
  "cookieDetail": "Détail des cookies",
  "ourSite": "sur notre site",
  "newWindow": "(nouvelle fenêtre)",
  "allowAll": "Tout accepter",
  "denyAll": "Continuer sans accepter",

  "fallback": "est désactivé.",

  "ads": {
      "title": "Régies publicitaires",
      "details": "Les régies publicitaires permettent de générer des revenus en commercialisant les espaces publicitaires du site."
  },
  "analytic": {
      "title": "Mesure d'audience",
      "details": "Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles à l'amélioration du site."
  },
  "social": {
      "title": "Réseaux sociaux",
      "details": "Les réseaux sociaux permettent d'améliorer la convivialité du site et aident à sa promotion via les partages."
  },
  "video": {
      "title": "Vidéos",
      "details": "Les services de partage de vidéo permettent d'enrichir le site de contenu multimédia et augmentent sa visibilité."
  },
  "comment": {
      "title": "Commentaires",
      "details": "Les gestionnaires de commentaires facilitent le dépôt de vos commentaires et luttent contre le spam."
  },
  "support": {
      "title": "Support",
      "details": "Les services de support vous permettent d'entrer en contact avec l'équipe du site et d'aider à son amélioration."
  },
  "api": {
      "title": "APIs",
      "details": "Les APIs permettent de charger des scripts : géolocalisation, moteurs de recherche, traductions, ..."
  },
  "other": {
      "title": "Autre",
      "details": "Services visant à afficher du contenu web."
  },

  "mandatoryTitle": "Cookies obligatoires",
  "mandatoryText": "Ce site utilise des cookies nécessaires à son bon fonctionnement qui ne peuvent pas être désactivés."
}

function setTarteauCitronPolicyLink () {
  setTimeout(() => {
    const footerPrivacyPolicy = document.querySelector('.o-js-policy-link')
    if (footerPrivacyPolicy) {
      const footerPrivacyPolicyUrl = footerPrivacyPolicy.href
      const tarteauCitronPolicyLink = document.querySelector('.o-js-tarteaucitron-policy-link')
      if (tarteauCitronPolicyLink) {
        tarteauCitronPolicyLink.setAttribute('href', footerPrivacyPolicyUrl)
      }
    }
  }, 1000);
}

setTarteauCitronPolicyLink()
