import { Controller } from 'stimulus'

export default class extends Controller {
  searchEvent (e) {
    const data = new FormData(e.target)
    for (var value of data.values()) {
      if (typeof fbq !== 'undefined') {
        fbq('track', 'Search')
      }

      if (typeof pintrk !== 'undefined') {
        pintrk('track', 'search', {
          search_query: value
        })
      }
    }
  }

  viewContentEvent () {
    if (typeof fbq !== 'undefined') {
      fbq('track', 'ViewContent')
    }
  }

  viewCategoryEvent () {
    if (typeof pintrk !== 'undefined') {
      pintrk('track', 'viewcategory')
    }
  }

  addToWishlistEvent () {
    if (typeof fbq !== 'undefined') {
      fbq('track', 'AddToWishlist')
    }
  }

  addToCartEvent (e) {
    const value = parseFloat(e.currentTarget.dataset.value).toFixed(2)
    const currency = e.currentTarget.dataset.currency
    const quantity = parseInt(e.currentTarget.dataset.quantity)

    if (typeof fbq !== 'undefined') {
      fbq('track', 'AddToCart')
    }

    if (typeof pintrk !== 'undefined') {
      pintrk('track', 'addtocart', {
        value: value,
        order_quantity: quantity,
        currency: currency
      })
    }

    if (e.currentTarget.dataset.packaging) {
      const packaging = e.currentTarget.dataset.packaging

      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          'event' : 'addToCart',
          'addToCart' : {
            'quantity' : quantity,
            'packaging' : packaging
          }
        })
      }
    } else if (e.currentTarget.dataset.variants) {
      const variants = JSON.parse(e.currentTarget.dataset.variants)
      variants.forEach((variant) => {
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({
            'event' : 'addToCart',
            'addToCart' : {
              'quantity' : 1,
              'packaging' : variant
            }
          })
        }
      })
    }
  }

  initiateCheckoutEvent () {
    if (typeof fbq !== 'undefined') {
      bq('track', 'InitiateCheckout')
    }
  }

  checkoutEvent (e) {
    const value = parseFloat(e.currentTarget.dataset.value).toFixed(2)
    const currency = e.currentTarget.dataset.currency
    const quantity = parseInt(e.currentTarget.dataset.quantity)

    if (typeof fbq !== 'undefined') {
      fbq('track', 'Purchase', {value: value, currency: currency})
    }

    if (typeof pintrk !== 'undefined') {
      pintrk('track', 'checkout', {
        value: value,
        order_quantity: quantity,
        currency: currency
      })
    }
  }

  contactEvent () {
    if (typeof fbq !== 'undefined') {
      fbq('track', 'Contact')
    }
  }

  newsletterEvent () {
    pintrk('track', 'signup')
  }
}
