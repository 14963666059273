import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['billingContainer', 'billingSelect', 'billingCheckbox']

  handleBillingFormVisibility () {
    const selectedAddress = this.element.querySelector('.o-js-checkout-address:checked')
    this.updateBillingSelect(selectedAddress)

    if (this.billingCheckboxTarget.checked) {
      this.billingContainerTarget.classList.add('is-d-none')
    } else {
      this.billingContainerTarget.classList.remove('is-d-none')
    }
  }

  updateBillingSelect (e) {
    const target = e.currentTarget || e
    const selectedValue = target.value

    this.billingSelectTarget.formsSelect.update(selectedValue)
  }
}
