import { Controller } from 'stimulus'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default class extends Controller {
  static targets = ['anchor', 'navContainer', 'nav', 'section']

  connect () {
    this.selectedIndex = 0

    if (this.hasNavTarget) {
      this.handleActiveTab()
      this.handlePositionNav()
    }
  }

  scrollToAnchor (e) {
    e.preventDefault()

    const offset = e.currentTarget.dataset.offset || 50

    gsap.to(window, {
      duration: 1,
      scrollTo: {y: e.currentTarget.getAttribute('href'), offsetY: offset},
      ease: 'power1.out'
    })

    if (!this.hasNavTarget) {
      this.anchorTargets.forEach((anchor) => {
        anchor.classList.remove('is-active')
      })

      e.currentTarget.classList.add('is-active')
    }
  }

  onScroll () {
    this.handleActiveTab()
    this.handlePositionNav()
  }

  handleActiveTab () {
    this.sectionTargets.forEach((section, index) => {
      if (window.scrollY >= section.offsetTop - 60 && window.scrollY < section.offsetHeight + section.offsetTop) {
        this.selectedIndex = index
      }
    })

    // If we reached bottom
    if (document.querySelector('body').offsetHeight - window.scrollY === window.innerHeight) {
      this.selectedIndex = this.sectionTargets.length - 1
    }

    this.anchorTargets.forEach((anchor) => {
      anchor.classList.remove('is-active')
    })
    if (this.anchorTargets[this.selectedIndex]) {
      this.anchorTargets[this.selectedIndex].classList.add('is-active')
    }
  }

  handlePositionNav() {
    const firstSection = this.sectionTargets[0]
    const firstSectionPositionTop = firstSection.getBoundingClientRect().top + window.pageYOffset
    const navContainerHeight = this.navContainerTarget.getBoundingClientRect().height
    const navHeight = this.navTarget.getBoundingClientRect().height

    if ((window.scrollY >= (firstSectionPositionTop - 50)) && ((window.scrollY + navHeight) < navContainerHeight - 50)) {
      this.navTarget.classList.add('is-fixed')
      this.navTarget.classList.remove('is-absolute')
    } else if ((window.scrollY + navHeight) >= navContainerHeight - 50) {
      this.navTarget.classList.remove('is-fixed')
      this.navTarget.classList.add('is-absolute')
    } else {
      this.navTarget.classList.remove('is-fixed')
      this.navTarget.classList.remove('is-absolute')
    }
  }
}
