import Glide from '@glidejs/glide'
import enquire from 'enquire.js'
import 'lightgallery.js'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['slider', 'thumbnails', 'thumbnail', 'img']
  static values = { init: Boolean, arrow: Boolean, thumbnails: Boolean, mobile: Boolean }

  connect () {
    if (this.initValue && !this.mobileValue) {
      enquire.register("screen and (min-width: 1024px)", {
        match: () => {
          this.initGallery()
        },
        unmatch: () => {
          if (this.element.getAttribute('lg-uid') !== '') {
            if (window.lgData[this.element.getAttribute('lg-uid')]) {
              window.lgData[this.element.getAttribute('lg-uid')].destroy(true)
            }
          }
        }
      })
    }

    if (this.mobileValue && this.initValue) {
      this.initGallery()
    }

    if (this.hasSliderTarget) {
      this.initSlider()
    }
  }

  initSlider () {
    this.glide = new Glide(this.sliderTarget, {
      type: 'carousel',
      startAt: 0,
      perView: 1,
      animationDuration: 0,
      gap: 0,
      breakpoints: {
        1280: {
          animationDuration: 400
        }
      }
    })

    this.glide.mount()
  }

  initGallery () {
    if (this.thumbnailsValue) {
      this.element.addEventListener('onAfterOpen', () => {
        const thumbnailsList = this.getThumbnailsHtml()
        document.querySelector('.lg').appendChild(thumbnailsList)
      })
    }

    if (this.arrowValue) {
      lightGallery(this.element, {
        selector: 'a',
        mode: 'lg-fade',
        addClass: 'o-light-gallery-reviews',
        enableDrag: false,
        controls: true,
        download: false,
        counter: false,
      })
    } else {
      lightGallery(this.element, {
        selector: 'a',
        mode: 'lg-fade',
        enableDrag: false,
        controls: false,
        download: false,
        counter: false,
      })
    }
  }

  selectImg (e) {
    e.preventDefault()
    e.stopPropagation()

    if (e.currentTarget.parentNode.className.indexOf('--lg') === -1) {
      this.selectSliderImg(e)
    } else {
      this.selectGalleryImg(e)
    }

    // Set is-active class on thumbnail
    if (!e.currentTarget.classList.contains('is-active')) {
      this.thumbnailTargets.forEach((thumbnail) => {
        thumbnail.classList.remove('is-active')
      })
      e.currentTarget.classList.add('is-active')
    }
  }

  selectSliderImg (e) {
    const selectedIndex = e.currentTarget.dataset.index
    this.glide.go('=' + selectedIndex)
  }

  selectGalleryImg (e) {
    const index = parseInt(e.currentTarget.dataset.index)
    window.lgData[this.element.getAttribute('lg-uid')].slide(index)
  }

  getThumbnailsHtml () {
    const list = this.thumbnailsTarget.cloneNode(true)
    list.removeAttribute('data-gallery-target')
    list.dataset.controller = 'gallery'
    list.dataset.galleryInitValue = 'false'
    list.setAttribute('lg-uid', this.element.getAttribute('lg-uid'))
    const className = list.className
    list.className = className + ' ' + className + '--lg'

    return list
  }

  clickOnSlideItem (e) {
    e.preventDefault()
  }
}
