import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['ordersTable', 'ordersList', 'quantityInput']
  static values = { itemsCount: Number }

  connect() {
    this.selectors = {
      counters: {
        cartHeader: '#cart-header-item-count',
      },
      lineItem: {
        destroyInput: '.o-js-line-item-delete-input',
      }
    }

    this.updateCounters()
  }

  onFormSuccess(event) {
    const modal = document.querySelector('.o-cart-content')
    const initialScroll = modal.scrollTop
    const [data, status, xhr] = event.detail

    if (xhr.response.includes('<form')) {
      this.element.outerHTML = xhr.response
      this.updateCounters()
      const newModal = document.querySelector('.o-cart-content')
      this.scrollingIntoModal(newModal, initialScroll)
    }
  }

  scrollingIntoModal(modal, distance) {
    modal.scrollTop = distance
  }

  onQuantityInputChange(event) {
    const value = event.currentTarget.value

    this.quantityInputTargets.forEach(input => {
      input.dataset.disabled = true

      if (input.dataset.lineItemId === event.currentTarget.dataset.lineItemId) {
        input.value = value
      }
    })

    this.update()
  }

  update(event) {
    const form = this.element

    this.load()

    if (form.dataset.remote == 'true') {
      Rails.fire(form, 'submit')
    } else {
      form.submit()
    }
  }

  updateCounters() {
    Object.values(this.selectors.counters).forEach(counter => {
      const counterElement = document.querySelector(counter)
      if (counterElement) counterElement.textContent = this.itemsCountValue
    })
  }

  load(loading = true) {
    if (this.hasOrdersTableTarget) this.ordersTableTarget.classList.toggle('is-loading', loading)
    if (this.hasOrdersListTarget) this.ordersListTarget.classList.toggle('is-loading', loading)
  }

  deleteItem(event) {
    event.currentTarget.parentNode.querySelector(this.selectors.lineItem.destroyInput).value = 1
    this.update()
  }
}
