import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'button']
  static values = { toggle: Boolean, open: Boolean }

  showText (e) {
    const text = e.currentTarget.dataset.text
    if (this.toggleValue) {
      const originalText = e.currentTarget.dataset.original
      if (text && originalText) {
        if (this.openValue) {
          this.contentTarget.innerHTML = originalText
          this.openValue = false
          this.buttonTarget.innerHTML = e.currentTarget.dataset.showText
        } else {
          this.contentTarget.innerHTML = text
          this.openValue = true
          this.buttonTarget.innerHTML = e.currentTarget.dataset.hideText
        }
      }
    } else {
      if (text) {
        this.contentTarget.innerHTML = text
      }
    }
  }
}
