import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['titleSmall', 'titleLarge', 'blockSmall', 'blockLarge', 'variantSmall', 'variantLarge', 'totalPrice', 'totalNumber', 'cartBtn']
  static values = {totalPrice: Number, totalNumber: Number, textUnique: String, textMultiple: String}

  initialize () {
    this.selectedVariants = []
  }

  connect () {
    this.setSelectedVariants()
  }

  setSelectedVariants () {
    this.variantSmallTargets.forEach((variant) => {
      if (variant.checked) {
        this.selectedVariants.push(variant.dataset.packaging)
        this.cartBtnTarget.dataset.variants = JSON.stringify(this.selectedVariants)
      }
    })
  }

  selectSize (e) {
    this.resetTotals()
    this.selectedVariants = []

    if (e.currentTarget.value === 'small') {
      this.hideLargeElements()
      this.showSmallElements()
      this.selectSmallIngredients()
      this.variantSmallTargets.forEach((variant) => {
        this.updateTotals(variant)
      })
    } else {
      this.hideSmallElements()
      this.showLargeElements()
      this.selectLargeIngredients()
      this.variantLargeTargets.forEach((variant) => {
        this.updateTotals(variant)
      })
    }

    this.updateTexts()
  }

  hideLargeElements () {
    this.titleLargeTarget.classList.add('is-hidden')
    this.blockLargeTarget.classList.add('is-hidden')
    this.variantLargeTargets.forEach((variant) => {
      variant.checked = false
    })
  }

  showLargeElements () {
    this.titleLargeTarget.classList.remove('is-hidden')
    this.blockLargeTarget.classList.remove('is-hidden')
  }

  hideSmallElements () {
    this.titleSmallTarget.classList.add('is-hidden')
    this.blockSmallTarget.classList.add('is-hidden')
    this.variantSmallTargets.forEach((variant) => {
      variant.checked = false
    })
  }

  showSmallElements () {
    this.titleSmallTarget.classList.remove('is-hidden')
    this.blockSmallTarget.classList.remove('is-hidden')
  }

  selectLargeIngredients () {
    this.variantLargeTargets.forEach((variant) => {
      if (variant.dataset.type === 'ingredient') {
        variant.checked = true
      }
    })
  }

  selectSmallIngredients () {
    this.variantSmallTargets.forEach((variant) => {
      if (variant.dataset.type === 'ingredient') {
        variant.checked = true
      }
    })
  }

  resetTotals () {
    this.totalPriceValue = 0
    this.totalNumberValue = 0
  }

  updateTotals (e) {
    this.handleCartBtnVisibility()

    let afterCheck = false

    if (e.currentTarget) {
      afterCheck = true
    }
    const target = e.currentTarget || e
    const itemPrice = parseFloat(target.dataset.price)

    if (target.checked) {
      this.totalPriceValue += itemPrice
      this.totalNumberValue += 1
      this.selectedVariants.push(target.dataset.packaging)
    } else if (!target.checked && afterCheck) {
      this.totalPriceValue -= itemPrice
      this.totalNumberValue -= 1
      this.selectedVariants.splice(this.selectedVariants.indexOf(target.dataset.packaging), 1)
    }

    if (afterCheck) {
      this.updateTexts()
    }

    this.cartBtnTarget.dataset.variants = JSON.stringify(this.selectedVariants)
    this.cartBtnTarget.dataset.value = this.totalPriceValue
    this.cartBtnTarget.dataset.quantity = this.totalNumberValue
  }

  updateTexts () {
    this.totalPriceTarget.textContent = this.formattedTotalPrice
    this.totalNumberTarget.textContent = this.totalNumberValue + ' '
    this.totalNumberTarget.textContent += this.totalNumberValue === 1 ? this.textUniqueValue : this.textMultipleValue
  }

  handleCartBtnVisibility () {
    let nbSelectedItems = 0
    this.variantSmallTargets.forEach((variantSmall) => {
      if (variantSmall.checked) {
        nbSelectedItems += 1
      }
    })
    this.variantLargeTargets.forEach((variantLarge) => {
      if (variantLarge.checked) {
        nbSelectedItems += 1
      }
    })

    if (nbSelectedItems === 0) {
      this.cartBtnTarget.classList.add('is-hidden')
    } else if (this.cartBtnTarget.classList.contains('is-hidden')) {
      this.cartBtnTarget.classList.remove('is-hidden')
    }
  }

  get formattedTotalPrice() {
    return `${(this.totalPriceValue).toFixed(2)} €`.replace('.', ',')
  }
}
