require('tarteaucitronjs/css/tarteaucitron.css')
const { tarteaucitron } = require('exports-loader?exports=tarteaucitron!tarteaucitronjs/tarteaucitron')
global.tarteaucitron = tarteaucitron

// require('tarteaucitronjs/lang/tarteaucitron.fr.js')
require('./lang/fr.js')
require('tarteaucitronjs/tarteaucitron.services.js')

async function reload(key) {
  const s = `[data-service-gdpr='${key}']`
  const currentElmt = document.querySelectorAll(`${s}[data-service-gdpr-enabled='false']`)

  if (currentElmt.length) {
    const response = await fetch(window.location.href)
    const html = await response.text()
    const parser = new DOMParser()
	  const doc = parser.parseFromString(html, 'text/html')
    const divs = doc.querySelectorAll(`${s}[data-service-gdpr-enabled='true']`)

    for (let i = 0; i < divs.length; ++i) {
      currentElmt[i].outerHTML = divs[i].outerHTML
    }
  }
}

// fix opsone for strict mode
tarteaucitron.fixSelfXSS = function(html) {
  return html.toString().replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
}

tarteaucitron.job = []

// Google Analytics
tarteaucitron.user.gtagUa = google_analytics_id;
tarteaucitron.user.gtagMore = function () { /* add here your optionnal gtag() */ };
tarteaucitron.job.push('gtag')

// Facebook
tarteaucitron.job.push('facebook')

// Facebook Pixel
tarteaucitron.user.facebookpixelId = facebookpixel_id;
tarteaucitron.user.facebookpixelMore = function () { /* add here your optionnal facebook pixel function */ };
tarteaucitron.job.push('facebookpixel')

// YouTube
tarteaucitron.services.youtube.js = function(){ reload(tarteaucitron.services.youtube.key) }
tarteaucitron.services.youtube.fallback = function(){}
tarteaucitron.job.push('youtube')

// MT captcha
tarteaucitron.user.mtcaptchaSitekey = mtcaptcha_public_key
tarteaucitron.services.mtcaptcha = {
  "key": "mtcaptcha",
  "type": "api",
  "name": "MTcaptcha",
  "uri": "https://www.mtcaptcha.com",
  "readmoreLink": "https://www.mtcaptcha.com/faq-cookie-declaration",
  "needConsent": false,
  "cookies": ['mtv1Pulse','mtv1ConfSum','mtv1Pong','mtv1*'],
  "render": "explicit",
  "renderQueue": [],
  "js": async function () {
    await reload(tarteaucitron.services.mtcaptcha.key)

    global.mtcaptchaConfig = {
      "sitekey": tarteaucitron.user.mtcaptchaSitekey
    };
    (function(){var mt_service = document.createElement('script');mt_service.async = true;mt_service.src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js';(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service);
    var mt_service2 = document.createElement('script');mt_service2.async = true;mt_service2.src = 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js';(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service2);}) ();
  }
};
tarteaucitron.job.push('mtcaptcha')

// Pinterest Pixel
// This code should be part of tarteaucitronjs next version - to be removed
tarteaucitron.services.pinterestpixel = {
  "key": "pinterestpixel",
  "type": "ads",
  "name": "Pinterest Pixel",
  "uri": "https://help.pinterest.com/fr/business/article/track-conversions-with-pinterest-tag",
  "needConsent": true,
  "cookies": [ '_pinterest_sess', '_pinterest_ct', '_pinterest_ct_mw', '_pinterest_ct_rt', '_epik', '_derived_epik', '_pin_unauth', '_pinterest_ct_ua'],
  "js": function () {
    'use strict'

    if (tarteaucitron.user.pinterestpixelId === undefined) {
      return
    }

    if(!window.pintrk) {
      window.pintrk = function() {
        window.pintrk.queue.push(Array.prototype.slice.call(arguments))
      };

      var n=window.pintrk
      n.queue=[]
      n.version="3.0"

      tarteaucitron.addScript('https://s.pinimg.com/ct/core.js', '', function() {
        window.pintrk('load', tarteaucitron.user.pinterestpixelId)
        window.pintrk('page')
      })
    }
  }
}
tarteaucitron.user.pinterestpixelId = pinterestpixel_id;
tarteaucitron.job.push('pinterestpixel')

tarteaucitron.init({
  hashtag: "#tarteaucitron", /* Ouverture automatique du panel avec le hashtag */
  highPrivacy: true, /* désactiver le consentement implicite (en naviguant) ? */
  orientation: "bottom", /* le bandeau doit être en haut (top) ou en bas (bottom) ? */
  groupServices: true, /* Group services by category */
  showAlertSmall: false, /* afficher le petit bandeau en bas à droite ? */
  showIcon: false,
  iconPosition: "BottomRight", /* Position of the icon between BottomRight, BottomLeft, TopRight and TopLeft */
  adblocker: false, /* Show a Warning if an adblocker is detected */
  DenyAllCta: true,/* Show the deny all button */
  AcceptAllCta: true, /* Show the accept all button when highPrivacy on */
  highPrivacy: true, /* HIGHLY RECOMMANDED Disable auto consent */
  cookieslist: false, /* Afficher la liste des cookies installés ? */
  removeCredit: false, /* supprimer le lien vers la source ? */
  handleBrowserDNTRequest: false, /* If Do Not Track == 1, disallow all */
  moreInfoLink: true, /* Show more info link */
  useExternalCss: true,
  useExternalJs: true,
  readmoreLink: "", /* Change the default readmore link pointing to tarteaucitron.io */
  mandatory: true, /* Show a message about mandatory cookies */
})

document.addEventListener('turbolinks:render', function () {
  tarteaucitron.added = {}
  tarteaucitron.initEvents.loadEvent(false)
})

tarteaucitron.triggerJobsAfterAjaxCall = function() {
  tarteaucitron.job.forEach(function(e) { tarteaucitron.job.push(e) });
  var allowBtns = document.getElementsByClassName("tarteaucitronAllow");
  for (i = 0; i < allowBtns.length; i++) {
      tarteaucitron.addClickEventToElement(allowBtns[i], function () {
          tarteaucitron.userInterface.respond(this, true);
      });
  }
  var denyBtns = document.getElementsByClassName("tarteaucitronDeny");
  for (i = 0; i < denyBtns.length; i++) {
      tarteaucitron.addClickEventToElement(denyBtns[i], function () {
          tarteaucitron.userInterface.respond(this, false);
      });
  }
}


setTimeout(function() {
  if (document.querySelector('#tarteaucitronPersonalize2') !== null) {
    document.querySelector('#tarteaucitronPersonalize2').addEventListener("click", function() {
      dataLayer.push({
        'event' : 'TACbuttonClick',
        'TACbuttonClick':{
          'name':'Accepter et fermer'
        }
      })
    })
  }

  if (document.querySelector('#tarteaucitronCloseAlert') !== null) {
    document.querySelector('#tarteaucitronCloseAlert').addEventListener("click", function() {
      dataLayer.push({
        'event' : 'TACbuttonClick',
        'TACbuttonClick':{
          'name':'Paramétrer les cookies'
        }
      })
    })
  }

  if (document.querySelector('#tarteaucitronAllDenied2') !== null) {
    document.querySelector('#tarteaucitronAllDenied2').addEventListener("click", function() {
      dataLayer.push({
        'event' : 'TACbuttonClick',
        'TACbuttonClick':{
          'name':'Continuer sans accepter'
        }
      })
    })
  }

  if (document.querySelector('#tarteaucitronClosePanel') !== null) {
    document.querySelector('#tarteaucitronClosePanel').addEventListener('click', function() {
      window.location.reload(true)
    })
  }
}, 2000)
